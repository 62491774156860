import dynamic from "next/dynamic";

const CardGrid = dynamic(() => import("./sections/card-grid/card-grid"));
const MiniCardGrid = dynamic(() =>
  import("./sections/mini-card-grid/mini-card-grid")
);
const CardAndMiniCardGrid = dynamic(() =>
  import("./sections/card-and-mini-card-grid/card-and-mini-card-grid")
);
const BigCardMedia = dynamic(() =>
  import("./sections/big-card-media/big-card-media")
);
const MiniCardCollage = dynamic(() =>
  import("./sections/mini-card-collage/mini-card-collage")
);
const InPageForm = dynamic(() =>
  import("./sections/in-page-form/in-page-form")
);
const Slider = dynamic(() => import("./sections/slider/slider"));
const SeoContentList = dynamic(() =>
  import("./sections/seo-content-list/seo-content-list")
);
const RichText = dynamic(() => import("./sections/rich-text/rich-text"));
const Highlighted = dynamic(() => import("./sections/highlighted/highlighted"));
const PageTitle = dynamic(() => import("./sections/page-title/page-title"));
const CheckList = dynamic(() => import("./sections/checklist/checklist"));
const Accordion = dynamic(() => import("./sections/accordion/accordion"));
const PriceTable = dynamic(() => import("./sections/price-table/price-table"));
const GoogleReviews = dynamic(() =>
  import("./sections/google-reviews/google-reviews")
);
const NextSteps = dynamic(() => import("./sections/next-steps/next-steps"));
const ReputationGrid = dynamic(() =>
  import("./sections/reputation-grid/reputation-grid")
);
const Contact = dynamic(() => import("./sections/contact/contact"));
const Simple = dynamic(() => import("./sections/simple/simple"));
const BannerMoreInformation = dynamic(() =>
  import("./sections/banner-more-information/banner-more-information")
);
const FeeOrientative = dynamic(() =>
  import("./sections/fee-orientative/fee-orientative")
);
const ServiceExample = dynamic(() =>
  import("./sections/service-example/service-example")
);
const FaqsModule = dynamic(() => import("./sections/faqs-module/faqs-module"));
const Pathologies = dynamic(() => import("./sections/pathologies/pathologies"));
const GoogleReviewsSelected = dynamic(() =>
  import("./sections/google-reviews-selected/google-reviews-selected")
);
const DropDownList = dynamic(() =>
  import("./sections/dropdown-list/dropdown-list")
);
const HorizontalCardList = dynamic(() =>
  import("./sections/horizontal-card-list/horizontal-card-list")
);
const CardsFlexible = dynamic(() =>
  import("./sections/cards-flexible/cards-flexible")
);
const CookiebotModification = dynamic(() =>
  import("./sections/cookiebot-modification/cookiebot-modification")
);
const TaskGrid = dynamic(() => import("./sections/task-grid/task-grid"));
const BlogGrid = dynamic(() => import("./sections/blog-grid/blog-grid"));
const Locations = dynamic(() => import("./sections/locations/locations"));

export const MAP_TYPENAME_COMPONENT = {
  ComponentSectionsCardGrid: CardGrid,
  ComponentSectionsMiniCardGrid: MiniCardGrid,
  ComponentSectionsCardAndMiniCardGrid: CardAndMiniCardGrid,
  ComponentSectionsBigCardMedia: BigCardMedia,
  ComponentSectionsMiniCardCollage: MiniCardCollage,
  ComponentSectionsInPageForm: InPageForm,
  ComponentSectionsSlider: Slider,
  ComponentSectionsSeoContentList: SeoContentList,
  ComponentSectionsRichText: RichText,
  ComponentSectionsHighlighted: Highlighted,
  ComponentSectionsPageTitle: PageTitle,
  ComponentSectionsCheckList: CheckList,
  ComponentSectionsAccordion: Accordion,
  ComponentSectionsPriceTable: PriceTable,
  ComponentSectionsGoogleReviews: GoogleReviews,
  ComponentSectionsNextSteps: NextSteps,
  ComponentSectionsReputationGrid: ReputationGrid,
  ComponentSectionsContact: Contact,
  ComponentSectionsSimple: Simple,
  ComponentSectionsBannerMoreInformation: BannerMoreInformation,
  ComponentSectionsFeeOrientative: FeeOrientative,
  ComponentSectionsServiceExample: ServiceExample,
  ComponentSectionsFaqsModule: FaqsModule,
  ComponentSectionsPathologies: Pathologies,
  ComponentSectionsGoogleReviewsSelected: GoogleReviewsSelected,
  ComponentSectionsDropDownList: DropDownList,
  ComponentSectionsHorizontalCardList: HorizontalCardList,
  ComponentSectionsCardsFlexible: CardsFlexible,
  ComponentSectionsCookiebotModification: CookiebotModification,
  ComponentSectionsTaskGrid: TaskGrid,
  ComponentSectionsBlogGrid: BlogGrid,
  ComponentSectionsLocations: Locations
};

export default MAP_TYPENAME_COMPONENT;
