import { gql } from "@apollo/client";
import { PhoneFragment } from "./elements";
import { FilePartsFragment, HubspotFormFragment, LinkFragment } from "./fields";

export const ModalFragment = () => gql`
  ${PhonesFragment()}
  ${NavigationModalFragment()}
  ${FormFragment()}
  ${ClaimFragment()}
  ${FooterModalFragment()}
  fragment Modal on Modals {
    id
    sections {
      ... on ComponentModalSectionsPhones {
        ...ModalSectionsPhones
      }
      ... on ComponentModalSectionsNavigation {
        ...ModalSectionsNavigation
      }
      ... on ComponentModalSectionsForm {
        ...ModalSectionsForm
      }
      ... on ComponentModalSectionsClaim {
        ...ModalSectionsClaim
      }
      ... on ComponentModalSectionsFooterModal {
        ...ModalSectionsFooterModal
      }
    }
  }
`;

export const ClaimFragment = () => gql`
  ${FilePartsFragment()}
  fragment ModalSectionsClaim on ComponentModalSectionsClaim {
    __typename
    id
    claim
    description
  }
`;

export const FooterModalFragment = () => gql`
  ${LinkFragment()}
  fragment ModalSectionsFooterModal on ComponentModalSectionsFooterModal {
    __typename
    id
    description
    cta {
      ...Link
    }
  }
`;

export const FormFragment = () => gql`
  ${HubspotFormFragment()}
  fragment ModalSectionsForm on ComponentModalSectionsForm {
    __typename
    id
    claimText
    description
    form {
      ...HubspotForm
    }
  }
`;

export const NavigationModalFragment = () => gql`
  ${LinkFragment()}
  ${FilePartsFragment()}
  fragment ModalSectionsNavigation on ComponentModalSectionsNavigation {
    __typename
    id
    title
    description
    featured
    image {
      ...FileParts
    }
    link {
      ...Link
    }
  }
`;

export const PhonesFragment = () => gql`
  ${PhoneFragment()}
  fragment ModalSectionsPhones on ComponentModalSectionsPhones {
    __typename
    id
    phones {
      ...ElementsPhone
    }
    description
  }
`;
