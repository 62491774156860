import { useState, useEffect } from 'react';

export const useIsWeekend = () => {
  const [isWeekend, setIsWeekend] = useState(false);

  useEffect(() => {
    const checkIfWeekend = () => {
      const spainTimezone = 'Europe/Madrid';
      const currentDate = new Date();
      const options = { timeZone: spainTimezone, weekday: 'long' };
      const dayOfWeek = new Intl.DateTimeFormat('en-US', options).format(currentDate);
      const isWeekendDay = dayOfWeek === 'Saturday' || dayOfWeek === 'Sunday';

      setIsWeekend(isWeekendDay);
    };
    checkIfWeekend();
    const intervalId = setInterval(checkIfWeekend, 600000);

    return () => clearInterval(intervalId);
  }, []);

  return isWeekend;
};

export default useIsWeekend;
