import { gql } from "@apollo/client";
import {
  FooterCardGridFragment,
  LinkGroupFragment,
  StripeFragment,
} from "./elements";

export const FilePartsFragment = () => gql`
  fragment FileParts on UploadFile {
    id
    alternativeText
    width
    height
    mime
    url
    formats
    caption
  }
`;

export const LinkFragment = () => gql`
  ${FilePartsFragment()}
  fragment Link on ComponentElementsLink {
    __typename
    id
    text
    icon {
      ...FileParts
    }
    href
    target
    page {
      locale
      slug
    }
    modal {
      id
    }
  }
`;

export const NavigationGroupLinkFragment = () => gql`
  ${LinkFragment()}
  fragment NavigationGroupLink on ComponentSharedNavigationGroupLink {
    parentLink {
      ...Link
    }
    links {
      ...Link
    }
  }
`;

export const NavigationFragment = () => gql`
  ${LinkFragment()}
  ${NavigationGroupLinkFragment()}
  fragment Navigation on ComponentSharedNavigation {
    phoneLink {
      ...Link
    }
    linksDesktop {
      ...NavigationGroupLink
    }
    linksMobile {
      ...NavigationGroupLink
    }
    ctasDesktop {
      ...Link
    }
    ctasMobile {
      ...Link
    }
    hideCtasMobile
  }
`;

export const HubspotFormFragment = () => gql`
  ${LinkFragment()}
  ${FilePartsFragment()}
  fragment HubspotForm on HubspotForms {
    formId
    portalId
    region
    defaultRedirect {
      locale
      slug
    }
    redirects {
      field
      value
      page {
        locale
        slug
      }
      href
    }
    sectionTitle
    sectionSubtitle
    sectionDescription
    hideLineSeparation
    subtitle
    description
    image {
      ...FileParts
    }
    ctaTitle
    ctaDescription
    cta {
      ...Link
    }
    descriptionPhoneNumberLink
    backgroundColor
    ctaTitleMobile
    ctaDescriptionMobile
    ctaDescriptionDesktop
  }
`;

export const FooterFragment = () => gql`
  ${StripeFragment()}
  ${FooterCardGridFragment()}
  ${LinkGroupFragment()}
  ${FilePartsFragment()}
  ${LinkFragment()}
  fragment Footer on Footer {
    stripe {
      ...Stripe
    }
    footerCardGrid {
      ...FooterCardGrid
    }
    contactTitle
    contactContent
    facebookLink {
      ...Link
    }
    twitterLink {
      ...Link
    }
    youtubeLink {
      ...Link
    }
    linkedinLink {
      ...Link
    }
    instagramLink {
      ...Link
    }
    officesLinks {
      ...Link
    }
    linkGroup {
      ...LinkGroup
    }
    certificatesTitle
    certificates {
      ...FileParts
    }
    agencyTitle
    agencyContent
    copyrightLinks {
      ...Link
    }
    collaboratorsTitle
    collaborators {
      ...FileParts
    }
  }
`;

export const HeaderFragment = () => gql`
  ${NavigationFragment()}
  fragment Header on Header {
    navigation {
      ...Navigation
    }
  }
`;

export const SeoMetadataFragment = () => gql`
  ${FilePartsFragment()}
  fragment SeoMetadata on ComponentSharedSeoMetadata {
    id
    title
    description
    keywords
    noIndex
    shareImage {
      ...FileParts
    }
  }
`;

export const GlobalMicrodataFragment = () => gql`
  ${FilePartsFragment()}
  fragment GlobalMicrodata on Microdata {
    organization
    website
    medicalBusiness
    logo {
      ...FileParts
    }
  }
`;

export const ProductFragment = () => gql`
  ${FilePartsFragment()}
  fragment Product on ComponentMicrodataProduct {
    __typename
    id
    name
    brand
    description
    image {
      ...FileParts
    }
    review {
      author
      rating
    }
    offer {
      price
      currency
      url
      priceValidUntil
    }
  }
`;

export const BannerFragment = () => gql`
  ${FilePartsFragment()}
  ${LinkFragment()}
  fragment Banner on Banners {
    __typename
    id
    title
    description
    link {
      ...Link
    }
    descriptionMobile
    linkMobile {
      ...Link
    }
    icon {
      ...FileParts
    }
    show
    showOnWeekendsOnly
  }
`;