import styled, { css } from "styled-components";

export const SectionWrapper = styled.section`
  --max-width: 1128px;
  position: relative;
  margin-bottom: var(--sectionSpacing);
  padding: 0 var(--mainPadding);
  width: 100%;

  @media screen and (min-width: 1128px) {
    padding: 0 calc((100vw - var(--max-width)) / 2);
    margin-bottom: var(--sectionSpacingDesktop);
    ${({ smallMargin }) =>
    smallMargin &&
    css`
    margin-bottom: var(--sectionSpacing);

    `}
      ${({ fullWidth }) =>
    fullWidth &&
    css`
   max-width: calc(1128px + (var(--footerPaddingDesktop) * 2));
    padding: var(--footerPaddingDesktop);
    overflow-x: hidden;

    `}
  }

  ${({ withBackgroundColor }) =>
    withBackgroundColor &&
    css`
      padding-top: var(--spacingM);
      padding-bottom: var(--spacingM);

      @media screen and (min-width: 1128px) {
        padding-top: var(--spacingL);
        padding-bottom: var(--spacingL);
      }
    `}
    ${({ displayMode }) =>
    displayMode === 'Desktop' &&
    css`
    @media screen and (max-width: 1128px) {
      display: none;
    }
    `}
  
  ${({ displayMode }) =>
    displayMode === 'Mobile' &&
    css`
    @media screen and (min-width: 1128px) {
      display: none;
    }
    `}
  
  ${({ displayMode }) =>
    displayMode === 'Hide' &&
    css`
      display: none;
    `}
`;

export const TitleSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  > h2 {
    font-size: 38px;
    line-height: 48px;
    margin-bottom: var(--spacingM);
    text-align: center;
    ${({ bigTitle }) =>
    bigTitle &&
    css`
        font-size: 60px;
    `}
  }

  > hr {
    width: 128px;
    border-top: 4px solid var(--primary800);
    margin-bottom: var(--spacingL);
  }

  @media screen and (max-width: 1128px) {
    > h2 {
      font-size: 30px;
      line-height: 40px;
       ${({ bigTitle }) =>
    bigTitle &&
    css`
        font-size: 38px;
    `}
    }
    > p {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: var(--spacingL);
    }
  }
`;

export const SubtitleWrapper = styled.div`
  margin-bottom: var(--spacing2Xl);

  p {
    font-size: 20px;
    line-height: 28px;
    margin: 0;
    text-align: center;
  }

  @media screen and (max-width: 1128px) {
    margin-bottom: var(--spacingL);

    p {
      font-size: 18px;
      line-height: 28px;
    }
  }
`;

export const SectionAnchor = styled.div`
  position: absolute;
  top: calc((var(--headerCompact) + var(--spacingXs)) * -1);
`;
