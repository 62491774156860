import { ReviewStarTrue, ReviewStarHalf, ReviewStarFalse } from "../lib/icons";

export const renderRatingStars = (rating) => {
  return [1, 2, 3, 4, 5].map((baseNumber, index) => {
    if (rating > baseNumber) return <i key={index}>{ReviewStarTrue}</i>;
    else if (Math.round(rating * 2) / 2 == baseNumber)
      return <i key={index}>{ReviewStarTrue}</i>;
    else if (Math.round(rating * 2) / 2 == baseNumber - 0.5)
      return <i key={index}>{ReviewStarHalf}</i>;
    else return <i key={index}>{ReviewStarFalse}</i>;
  });
};
