import { gql } from "@apollo/client";
import { GlobalMicrodataFragment, SeoMetadataFragment } from "./fields";
import {
  BigCardMediaFragment,
  CardAndMiniCardGridFragment,
  CardGridFragment,
  HighlightedFragment,
  InPageFormFragment,
  MiniCardGridFragment,
  PageTitleFragment,
  RichTextFragment,
} from "./sections";

export const GET_POSTS = gql`
  query GetPosts($locale: String!) {
    posts(locale: $locale) {
      id
      slug
      locale
    }
  }
`;

export const GET_POST_BY_SLUG = gql`
  ${InPageFormFragment()}
  ${CardAndMiniCardGridFragment()}
  ${CardGridFragment()}
  ${MiniCardGridFragment()}
  ${BigCardMediaFragment()}
  ${RichTextFragment()}
  ${SeoMetadataFragment()}
  ${GlobalMicrodataFragment()}
  ${HighlightedFragment()}
  ${PageTitleFragment()}
  query GetPage($slug: String!, $locale: String!) {
    microdatum(locale: $locale) {
      ...GlobalMicrodata
    }
    posts(where: { slug: $slug }, locale: $locale) {
      id
      title
      slug
      locale
      localizations {
        slug
        locale
      }
      seoMetadata {
        ...SeoMetadata
      }
      sections {
        ... on ComponentSectionsRichText {
          ...RichText
        }
        ... on ComponentSectionsCardGrid {
          ...CardGrid
        }
        ... on ComponentSectionsMiniCardGrid {
          ...MiniCardGrid
        }
        ... on ComponentSectionsBigCardMedia {
          ...BigCardMedia
        }
        ... on ComponentSectionsInPageForm {
          ...InPageForm
        }
        ... on ComponentSectionsCardAndMiniCardGrid {
          ...CardAndMiniCardGrid
        }
        ... on ComponentSectionsHighlighted {
          ...Highlighted
        }
        ... on ComponentSectionsPageTitle {
          ...PageTitle
        }
        ... on ComponentSectionsTaskGrid {
          ...TaskGrid
        }
      }
    }
  }
`;
