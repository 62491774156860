import { getStrapiMediaUrl } from "../../utils";

export const getImageObject = ({
  id = null,
  url,
  width,
  height,
  caption,
  locale,
}) => {
  const absoluteUrl = getStrapiMediaUrl(url);
  const idAttr = {};
  if (id) {
    idAttr["@id"] = `https://qida.es/#${id}`;
  }
  return {
    "@type": "ImageObject",
    ...idAttr,
    name: "Qida logo",
    inLanguage: locale,
    url: absoluteUrl,
    contentUrl: absoluteUrl,
    width,
    height,
    caption,
  };
};

export default getImageObject;
