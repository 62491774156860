export const getModel = ({
  title,
  description,
  absoluteUrl,
  locale,
  created_at,
  updated_at,
}) => {
  const id = `${absoluteUrl}/#webpage`;
  return {
    "@type": "MedicalWebPage",
    "@id": id,
    url: absoluteUrl,
    name: title,
    description,
    isPartOf: {
      "@id": "https://qida.es/#website",
    },
    about: {
      "@id": "https://qida.es/#organization",
    },
    // primaryImageOfPage: {
    //   "@id": "https://qida.es/#primaryimage",
    // },
    datePublished: new Date(created_at)?.toISOString(),
    dateModified: new Date(updated_at)?.toISOString(),
    // breadcrumb: {
    //   "@id": "https://qida.es/#breadcrumb",
    // },
    inLanguage: locale,
  };
};

export default {
  getModel,
};
