import MAP_TYPENAME_COMPONENT from "../map-typename-component";

export const Sections = ({ sections = [] }) => {
  return (
    <>
      {sections.map((section) => {
        const SectionComponent = MAP_TYPENAME_COMPONENT[section.__typename];
        if (!SectionComponent) {
          return null;
        }
        return (
          <SectionComponent
            key={
              section.id
                ? section.__typename + section.id
                : Math.floor(Math.random() * 1000)
            }
            {...section}
          />
        );
      })}
    </>
  );
};
export default Sections;
