import { useRouter } from "next/router";
import { useRef, useEffect } from "react";
import tw from "tailwind-styled-components";
import { triggerModalHasToBeClosed } from "./layout";

const Bar = tw.div`
  fixed
  h-1
  z-50
  rounded-r-full
  bg-cta-800
  transition-[width]
`;

export const RouterProgressBar = () => {
  const router = useRouter();
  const element = useRef();

  const MS_TO_CHARGE = 7000;
  const MS_TO_FINISH = 500;

  useEffect(() => {
    element.current.style.width = "0%";
  }, []);

  useEffect(() => {
    router.events.on("routeChangeStart", startTransition);
    router.events.on("routeChangeComplete", finishTransition);

    return () => {
      router.events.off("routeChangeStart", startTransition);
      router.events.off("routeChangeComplete", finishTransition);
    };
  }, [router]);

  const startTransition = () => {
    triggerModalHasToBeClosed();
    element.current.style.transitionDuration = `${MS_TO_CHARGE}ms`;
    element.current.style.transitionTimingFunction =
      "cubic-bezier(0, 0.5, 0, 1)";
    element.current.style.width = "95%";
  };

  const finishTransition = () => {
    element.current.style.transitionDuration = `${MS_TO_FINISH}ms`;
    element.current.style.transitionTimingFunction = "easy-in";
    element.current.style.width = "100%";
    window.scrollTo({ top: 0, behavior: "instant" });

    setTimeout(() => {
      element.current.style.transitionDuration = "10ms";
      element.current.style.width = "0%";
    }, MS_TO_FINISH);
  };

  return <Bar ref={element} />;
};

export default RouterProgressBar;
