export const getPhoneNumber = (link, withSpaces = false) => {
  if (!link.href) return "";
  const numberPerBlocks = [];

  const digitsArray = link.href.split("").filter((c) => !isNaN(Number(c)));

  digitsArray.slice(-9).forEach((number, i, numbers) => {
    if (i + 1 >= 3 && (i + 1) % 3 === 0) {
      const elements = [numbers[i - 2], numbers[i - 1], number];
      numberPerBlocks.push(elements.join(""));
    }
  });

  return numberPerBlocks.join(withSpaces ? " " : "");
};
