import Head from "next/head";
import { getStrapiMediaUrl } from "../../utils";

export const SeoMetadata = ({
  title,
  description,
  absoluteUrl,
  shareImage,
  keywords,
  noIndex,
}) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} key="description" />
      {keywords && <meta name="keywords" content={keywords} key="keywords" />}

      {/* Twitter card */}
      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twitter:card"
      />
      <meta
        name="twitter_card"
        content="summary_large_image"
        key="twitter_card"
      />
      <meta name="twitter_creator" content="@QIDAcare" key="twitter_creator" />
      <meta name="twitter_site" content="@QIDAcare" key="twitter_site" />

      {/* open-graph */}
      <meta property="og:url" content={absoluteUrl} key="og:url" />
      <meta property="og:title" content={title} key="og:title" />
      <meta
        property="og:description"
        content={description}
        key="og:description"
      />
      {shareImage && (
        <meta
          property="og:image"
          content={getStrapiMediaUrl(shareImage.url)}
          key="og:image"
        />
      )}

      {/* others */}
      <link rel="canonical" href={absoluteUrl} key="canonical" />
      {noIndex && (
        <>
          <meta name="robots" content="noindex" key="robots" />
          <meta name="googlebot" content="noindex" key="googlebot" />
        </>
      )}
    </Head>
  );
};

export default SeoMetadata;
