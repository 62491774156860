import EditorJSContent from "../../editor-js-content";
import {
  SectionWrapper,
  TitleSectionWrapper,
  SubtitleWrapper,
  SectionAnchor,
} from "./styles";

export const TitleSection = ({ title, subtitle, hideLine, bigTitle }) => {
  const notRender = !title && !subtitle;
  const renderSeparator = !hideLine;

  if (notRender) return null;

  return (
    <TitleSectionWrapper bigTitle={bigTitle}>
      {title && <h2>{title}</h2>}
      {renderSeparator && <hr />}
      {subtitle && (
        <SubtitleWrapper>
          <EditorJSContent content={subtitle} />
        </SubtitleWrapper>
      )}
    </TitleSectionWrapper>
  );
};

export const Section = ({
  title,
  subtitle,
  children,
  id,
  backgroundColor,
  hideLine,
  displayMode = 'All',
  smallMargin,
  fullWidth = false,
  bigTitle = false
}) => {
  const withBackgroundColor = !!backgroundColor;

  return (
    <SectionWrapper
      id={id && id + "-section"}
      style={{ backgroundColor: backgroundColor }}
      withBackgroundColor={withBackgroundColor}
      displayMode={displayMode}
      smallMargin={smallMargin}
      fullWidth={fullWidth}
    >
      {id && <SectionAnchor id={id} />}
      {title && <TitleSection title={title} subtitle={subtitle} hideLine={hideLine} bigTitle={bigTitle} />}
      {children}
    </SectionWrapper>
  );
};

export default Section;
