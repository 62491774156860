import { useEffect, useRef } from "react";
import styled from "styled-components";
import { useOverlayTriggerState } from "@react-stately/overlays";
import {
  useOverlay,
  usePreventScroll,
  useModal as useReactAriaModal,
  OverlayContainer,
} from "@react-aria/overlays";
import { useDialog } from "@react-aria/dialog";
import { FocusScope } from "@react-aria/focus";
import { useButton } from "@react-aria/button";

import { CloseIcon } from "../lib/icons";
import { ModalSections } from "./modal-sections";
import { triggerModalHasToBeClosed } from "./layout";

const OverlayWrapper = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  overflow: auto;
  padding: 0 var(--spacingS);

  @media screen and (min-width: 768px) {
    padding: var(--spacingM);
  }

  @media screen and (min-width: 1128px) {
    padding: var(--spacingL) 0;
  }
`;

const OverlayContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  border-radius: var(--radiusL);
  width: 100%;
  margin: auto;
  top: 50%;
  transform: translate(0, -50%);
  max-height: 100vh;

  &:focus {
    outline: none;
  }

  @media screen and (min-width: 1128px) {
    max-width: 1128px;
    width: fit-content;
  }

  ${({ fullvh }) => fullvh && "height: 100%"}
`;

const HeaderButtonWrapper = styled.a`
  position: absolute;
  top: 16px;
  ${({ closeLeft }) => closeLeft ? "left: 16px;": "right: 16px;"}
  z-index: 10;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const acceptCookiebotImplied = () => {
  if (!window.Cookiebot || !window.CookiebotDialog) return;
  if (
    !window.Cookiebot.hasResponse &&
    window.CookiebotDialog.consentLevel == "implied"
  ) {
    window.CookiebotDialog.submitConsent(true, document.location.href);
  }
};

export const useModal = () => {
  const state = useOverlayTriggerState({});
  const openButtonRef = useRef();
  const closeButtonRef = useRef();
  const { buttonProps: openButtonProps } = useButton(
    {
      onPress: (e) => {
        acceptCookiebotImplied();
        state.open();
      },
    },
    openButtonRef
  );

  const { buttonProps: closeButtonProps } = useButton(
    {
      onPress: () => {
        state.close();
        triggerModalHasToBeClosed();
      },
    },
    closeButtonRef
  );

  const triggerProps = {
    ...openButtonProps,
    ref: openButtonRef,
    as: "button",
    className: "open-modal-button",
  };

  const closeTriggerProps = {
    ...closeButtonProps,
    ref: closeButtonRef,
    as: "button",
  };

  const modalProps = { state, closeTriggerProps };

  return { triggerProps, modalProps, state };
};

const ModalWrapper = (props) => {
  const { children, fullvh } = props;
  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  let ref = useRef();
  let { overlayProps, underlayProps } = useOverlay(
    {
      ...props,
      onClose: () => {
        props.onClose();
        triggerModalHasToBeClosed();
      },
    },
    ref
  );

  // Prevent scrolling while the modal is open, and hide content
  // outside the modal from screen readers.
  usePreventScroll();
  let { modalProps } = useReactAriaModal();

  // Get props for the dialog and its title
  let { dialogProps, titleProps } = useDialog(props, ref);

  return (
    <OverlayWrapper {...underlayProps}>
      <FocusScope contain restoreFocus autoFocus>
        <OverlayContent
          {...overlayProps}
          {...dialogProps}
          {...modalProps}
          ref={ref}
          fullvh={fullvh}
        >
          {children}
        </OverlayContent>
      </FocusScope>
    </OverlayWrapper>
  );
};

export const Modal = ({
  state,
  closeTriggerProps,
  children,
  sections,
  fullvh,
  closeLeft
}) => {
  const closeModal = () => {
    state.close();
  };

  useEffect(() => {
    if (state.isOpen) {
      document.addEventListener("modalHasToBeClosed", closeModal);
    }
    return () => {
      document.removeEventListener("modalHasToBeClosed", closeModal);
    };
  }, [state.isOpen]);

  if (!state.isOpen) {
    return null;
  }

  return (
    <OverlayContainer>
      <ModalWrapper isOpen onClose={state.close} isDismissable fullvh={fullvh}>
        <HeaderButtonWrapper {...closeTriggerProps} closeLeft={closeLeft}>
          {CloseIcon}
        </HeaderButtonWrapper>
        {children ? children : <ModalSections sections={sections} />}
      </ModalWrapper>
    </OverlayContainer>
  );
};

export default Modal;
