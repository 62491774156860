import dynamic from "next/dynamic";

const Phones = dynamic(() => import("./phones/phones"));
const Navigation = dynamic(() => import("./navigation/navigation"));
const Form = dynamic(() => import("./form/form"));
const Claim = dynamic(() => import("./claim/claim"));
const FooterModal = dynamic(() => import("./footer-modal/footer-modal"));

const MAP_TYPENAME_COMPONENT = {
  ComponentModalSectionsPhones: Phones,
  ComponentModalSectionsNavigation: Navigation,
  ComponentModalSectionsForm: Form,
  ComponentModalSectionsClaim: Claim,
  ComponentModalSectionsFooterModal: FooterModal,
};

export const ModalSections = ({ sections = [] }) => {
  return (
    <>
      {sections.map((section) => {
        const SectionComponent = MAP_TYPENAME_COMPONENT[section.__typename];
        if (!SectionComponent) {
          return null;
        }
        return (
          <SectionComponent
            {...section}
            key={section.__typename + section.id}
          />
        );
      })}
    </>
  );
};
export default ModalSections;
