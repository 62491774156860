export const CaretDownIcon = (
  <svg
    width="16"
    height="10"
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 9.50019L0.5 2.00019L1.55 0.950195L8 7.4002L14.45 0.950195L15.5 2.00019L8 9.50019Z"
      fill="#333333"
    />
  </svg>
);

export const ArrowRightIcon = (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5003 0.500632L9.44279 1.55816L15.1279 7.25079L7.1758e-05 7.25079L7.18891e-05 8.75082L15.1279 8.75082L9.44279 14.4435L10.5003 15.501L18.0005 8.0008L10.5003 0.500632Z"
      fill="#333333"
    />
  </svg>
);

export const CloseIcon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 1.05L10.95 0L6 4.95L1.05 0L0 1.05L4.95 6L0 10.95L1.05 12L6 7.05L10.95 12L12 10.95L7.05 6L12 1.05Z"
      fill="#333333"
    />
  </svg>
);

export const HamburgerMenuIcon = (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="37"
      height="37"
      rx="18.5"
      fill="white"
      stroke="#004039"
    />
    <path d="M28 11.5H10V13H28V11.5Z" fill="#004039" />
    <path d="M28 25H10V26.5H28V25Z" fill="#004039" />
    <path d="M28 16H10V17.5H28V16Z" fill="#004039" />
    <path d="M28 20.5H10V22H28V20.5Z" fill="#004039" />
  </svg>
);

export const CheckmarkFilled = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 0C11.2311 0 8.52431 0.821086 6.22202 2.35943C3.91973 3.89777 2.12532 6.08427 1.06569 8.64243C0.00606596 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C28 10.287 26.525 6.72601 23.8995 4.1005C21.274 1.475 17.713 0 14 0ZM12 19.5908L7.00001 14.5908L8.59061 13L12 16.4092L19.41 9L21.0057 10.5859L12 19.5908Z"
      fill="#004039"
    />
  </svg>
);

export const Checkmark = (
  <svg
    width="12"
    height="9"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 8.99997L0 4.49997L0.707 3.79297L4.5 7.58547L11.293 0.792969L12 1.49997L4.5 8.99997Z"
      fill="#333333"
    />
  </svg>
);

export const ChevronUp = (
  <svg
    width="20"
    height="12"
    viewBox="0 0 20 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.000392834L20 10.0004L18.6 11.4004L10 2.80039L1.4 11.4004L-1.22392e-07 10.0004L10 0.000392834Z"
      fill="#333333"
    />
  </svg>
);

export const ChevronDown = (
  <svg
    width="20"
    height="12"
    viewBox="0 0 20 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 11.9996L0 1.99961L1.4 0.599609L10 9.19961L18.6 0.599609L20 1.99961L10 11.9996Z"
      fill="#333333"
    />
  </svg>
);

export const PhoneIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" id="icon" viewBox="0 0 32 32">
    <path
      d="M26,29h-.17C6.18,27.87,3.39,11.29,3,6.23A3,3,0,0,1,5.76,3h5.51a2,2,0,0,1,1.86,1.26L14.65,8a2,2,0,0,1-.44,2.16l-2.13,2.15a9.37,9.37,0,0,0,7.58,7.6l2.17-2.15A2,2,0,0,1,24,17.35l3.77,1.51A2,2,0,0,1,29,20.72V26A3,3,0,0,1,26,29ZM6,5A1,1,0,0,0,5,6v.08C5.46,12,8.41,26,25.94,27A1,1,0,0,0,27,26.06V20.72l-3.77-1.51-2.87,2.85L19.88,22C11.18,20.91,10,12.21,10,12.12l-.06-.48,2.84-2.87L11.28,5Z"
      transform="translate(0)"
    />
  </svg>
);

export const Book = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M26 10.001H19V12.001H26V10.001Z" fill="#333333" />
    <path d="M26 15.0015H19V17.0015H26V15.0015Z" fill="#333333" />
    <path d="M26 20.002H19V22.0019H26V20.002Z" fill="#333333" />
    <path d="M13 10.001H6V12.001H13V10.001Z" fill="#333333" />
    <path d="M13 15.0015H6V17.0015H13V15.0015Z" fill="#333333" />
    <path d="M13 20.002H6V22.0019H13V20.002Z" fill="#333333" />
    <path
      d="M28 5.00049H4C3.46973 5.00102 2.96133 5.2119 2.58637 5.58686C2.21141 5.96181 2.00053 6.47021 2 7.00048V25.0004C2.00053 25.5307 2.21141 26.0391 2.58637 26.414C2.96133 26.789 3.46973 26.9999 4 27.0004H28C28.5303 26.9999 29.0387 26.789 29.4136 26.414C29.7886 26.0391 29.9995 25.5307 30 25.0004V7.00048C29.9995 6.47021 29.7886 5.96181 29.4136 5.58686C29.0387 5.2119 28.5303 5.00102 28 5.00049ZM4 7.00048H15V25.0004H4V7.00048ZM17 25.0004V7.00048H28V25.0004H17Z"
      fill="#333333"
    />
  </svg>
);

export const Play = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
    <path
      d="M361 215C375.3 223.8 384 239.3 384 256C384 272.7 375.3 288.2 361 296.1L73.03 472.1C58.21 482 39.66 482.4 24.52 473.9C9.377 465.4 0 449.4 0 432V80C0 62.64 9.377 46.63 24.52 38.13C39.66 29.64 58.21 29.99 73.03 39.04L361 215z"
      fill="#004039"
    />
  </svg>
);

export const Pause = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
    <path
      d="M272 63.1l-32 0c-26.51 0-48 21.49-48 47.1v288c0 26.51 21.49 48 48 48L272 448c26.51 0 48-21.49 48-48v-288C320 85.49 298.5 63.1 272 63.1zM80 63.1l-32 0c-26.51 0-48 21.49-48 48v288C0 426.5 21.49 448 48 448l32 0c26.51 0 48-21.49 48-48v-288C128 85.49 106.5 63.1 80 63.1z"
      fill="#ea6d43"
    />
  </svg>
);

export const GoogleIconColored = (
  <svg viewBox="0 0 104 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M44.5538 17.8912C44.5538 22.7828 40.727 26.3874 36.0308 26.3874C31.3345 26.3874 27.5078 22.7828 27.5078 17.8912C27.5078 12.9651 31.3345 9.39502 36.0308 9.39502C40.727 9.39502 44.5538 12.9651 44.5538 17.8912ZM40.8228 17.8912C40.8228 14.8344 38.6049 12.7429 36.0308 12.7429C33.4567 12.7429 31.2388 14.8344 31.2388 17.8912C31.2388 20.9173 33.4567 23.0395 36.0308 23.0395C38.6049 23.0395 40.8228 20.9135 40.8228 17.8912Z"
      fill="#EA4335"
    />
    <path
      d="M62.9386 17.8912C62.9386 22.7828 59.1118 26.3874 54.4156 26.3874C49.7193 26.3874 45.8926 22.7828 45.8926 17.8912C45.8926 12.9689 49.7193 9.39502 54.4156 9.39502C59.1118 9.39502 62.9386 12.9651 62.9386 17.8912ZM59.2076 17.8912C59.2076 14.8344 56.9897 12.7429 54.4156 12.7429C51.8414 12.7429 49.6235 14.8344 49.6235 17.8912C49.6235 20.9173 51.8414 23.0395 54.4156 23.0395C56.9897 23.0395 59.2076 20.9135 59.2076 17.8912Z"
      fill="#FBBC05"
    />
    <path
      d="M80.559 25.1614C80.559 31.4358 76.8587 33.9985 72.4842 33.9985C68.3664 33.9985 65.888 31.2443 64.9533 28.9919L68.2016 27.6398C68.7801 29.0226 70.1974 30.6544 72.4804 30.6544C75.2805 30.6544 77.0158 28.9268 77.0158 25.6747V24.4528H76.8855C76.0505 25.4832 74.4416 26.3833 72.4114 26.3833C68.1633 26.3833 64.2715 22.683 64.2715 17.9217C64.2715 13.1258 68.1633 9.3949 72.4114 9.3949C74.4378 9.3949 76.0466 10.2951 76.8855 11.2948H77.0158V9.91202H80.559V25.1614ZM77.2801 17.9217C77.2801 14.93 75.2843 12.7428 72.7447 12.7428C70.1705 12.7428 68.0139 14.93 68.0139 17.9217C68.0139 20.8827 70.1705 23.0393 72.7447 23.0393C75.2843 23.0393 77.2801 20.8827 77.2801 17.9217Z"
      fill="#4285F4"
    />
    <path
      d="M86.4027 0.967651V25.8662H82.7637V0.967651H86.4027Z"
      fill="#34A853"
    />
    <path
      d="M100.583 20.6913L103.479 22.6219C102.544 24.0048 100.292 26.3874 96.3998 26.3874C91.5733 26.3874 87.9688 22.6564 87.9688 17.8912C87.9688 12.8387 91.6039 9.39502 95.9822 9.39502C100.391 9.39502 102.548 12.9038 103.253 14.7999L103.639 15.7652L92.2819 20.4692C93.1515 22.1738 94.5037 23.0433 96.3998 23.0433C98.2997 23.0433 99.6174 22.1086 100.583 20.6913ZM91.6691 17.6346L99.2612 14.482C98.8437 13.4209 97.5872 12.6816 96.1087 12.6816C94.2125 12.6816 91.5733 14.3556 91.6691 17.6346Z"
      fill="#EA4335"
    />
    <path
      d="M13.7317 15.6822V12.0777H25.8783C25.9971 12.7059 26.0584 13.449 26.0584 14.2535C26.0584 16.9578 25.3191 20.3019 22.9365 22.6845C20.619 25.0977 17.658 26.3848 13.7355 26.3848C6.46512 26.3848 0.351562 20.4628 0.351562 13.1924C0.351562 5.92202 6.46512 0 13.7355 0C17.7576 0 20.6228 1.57818 22.7756 3.63519L20.2321 6.17867C18.6884 4.73072 16.5969 3.60454 13.7317 3.60454C8.42253 3.60454 4.27021 7.88326 4.27021 13.1924C4.27021 18.5015 8.42253 22.7802 13.7317 22.7802C17.1753 22.7802 19.1366 21.3974 20.393 20.141C21.4119 19.1221 22.0823 17.6665 22.3466 15.6784L13.7317 15.6822Z"
      fill="#4285F4"
    />
  </svg>
);

export const ReviewStarTrue = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 1.5L8.58746 8.415L0.959961 9.5175L6.47996 14.9025L5.17496 22.5L12 18.915L18.825 22.5L17.52 14.9025L23.04 9.525L15.4125 8.415L12 1.5Z"
      fill="#FBBC05"
    />
  </svg>
);

export const ReviewStarHalf = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9999 1.5L8.58746 8.41498L0.959961 9.51752L6.47998 14.9025L5.17499 22.5L11.9999 18.915V1.5Z"
      fill="#FBBC05"
    />
    <path
      d="M12.0001 1.5L15.4125 8.41498L23.04 9.51752L17.52 14.9025L18.825 22.5L12.0001 18.915V1.5Z"
      fill="#CCCCCC"
    />
  </svg>
);

export const ReviewStarFalse = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 1.5L8.58746 8.415L0.959961 9.5175L6.47996 14.9025L5.17496 22.5L12 18.915L18.825 22.5L17.52 14.9025L23.04 9.525L15.4125 8.415L12 1.5Z"
      fill="#CCCCCC"
    />
  </svg>
);
