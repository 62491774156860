export const renderLineBreaks = (text) => {
  if (!text) {
    return "";
  }
  const lines = text.split("\n");
  return lines.map((line, i) => (
    <span key={i}>
      {line}
      {lines.length - 1 !== i && <br />}
    </span>
  ));
};

export default renderLineBreaks;
