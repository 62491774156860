import Head from "next/head";
import getImageObject from "./image-object";
import medicalWebpage from "./medical-webpage";

export const GlobalMicrodata = ({ microdata, pageContext, locale }) => {
  const { organization, website, medicalBusiness, logo } = microdata || {};
  const { title, description, absoluteUrl, created_at, updated_at } =
    pageContext;

  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      getImageObject({ ...logo, locale, id: "logo" }),
      organization,
      website,
      medicalBusiness,
      medicalWebpage.getModel({
        title,
        description,
        absoluteUrl,
        created_at,
        updated_at,
      }),
    ],
  };

  const insertJSONLD = () => {
    return { __html: JSON.stringify(schema, null, 2) };
  };

  return (
    <Head>
      <script
        type="application/ld+json"
        key="global_microdata"
        dangerouslySetInnerHTML={insertJSONLD()}
      ></script>
    </Head>
  );
};

export default GlobalMicrodata;
