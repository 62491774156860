import React from "react";

const PageDataContext = React.createContext();

export const usePageData = () => {
  const context = React.useContext(PageDataContext);
  return context;
};

export const PageDataProvider = ({ children, pageData }) => {
  return (
    <PageDataContext.Provider value={pageData}>
      {children}
    </PageDataContext.Provider>
  );
};
