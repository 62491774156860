import { useEffect, useState } from "react";
import { usePageData } from "../page-components/page-data-provider";

export function useIntersectionObserver(
  querySelectorElement = "",
  options = {}
) {
  const { pageContext } = usePageData();
  const { slug } = pageContext;
  const [entry, setEntry] = useState();

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setEntry(entry);
    }, options);
    const element = document.querySelector(querySelectorElement);
    if (element) {
      observer.observe(element);
    }
    return () => observer.disconnect();
  }, [slug]);

  return entry;
}
